import emailjs from 'emailjs-com';

export function sendEmail(client:string, clientMessage: string, clientContact: string) {    

    const adminEmail = 'info@pentutoring.co.za';
    const templateParams = {        
        message:clientMessage,
        from_name:client,
        contact: clientContact,
        admin: adminEmail
    }
    
    emailjs.send('service_o6eqva7', 'template_cnw3hke', templateParams, '06Op8rr-KPEMqvC3t');      
}
